@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: Montserrat-Regular;
  src: url(./fonts/Montserrat-Regular.ttf);
}
@font-face {
  font-family: Montserrat-Bold;
  src: url(./fonts/Montserrat-Bold.ttf);
}
@font-face {
  font-family: Montserrat-Black;
  src: url(./fonts/Montserrat-Black.ttf);
}

@keyframes shiftUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  3% {
    transform: translateY(3px);
    opacity: 1;
  }

  30% {
    transform: translateY(-3px);
    opacity: 1;
  }

  33% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}

@keyframes bobAround {
  0% {
    translate: 10px;
    rotate: 0deg;
  }

  30% {
    translate: 50px;
  }

  60% {
    rotate: 15deg;
  }

  100% {
    translate: 10px;
    rotate: 0deg;
  }
}

@keyframes subtleBob {
  0% {
    translate: 0px;
  }

  30% {
    translate: -20px;
    rotate: -3deg;
  }

  60% {
    translate: 20px;
    rotate: 3deg;
  }
}

body {
  margin: 0px;
  overflow-x: hidden;
  font-family: "Montserrat-Regular", sans-serif;
  scroll-behavior: smooth;

}
p {
}
a {
  font-family: "Montserrat-Regular", sans-serif;
}
div {
  font-family: "Montserrat-Regular", sans-serif;
  overflow-x: hidden;
  scroll-behavior: smooth;

}

.background-container {
  height: 0px;
}

.background {
  position: fixed;
  background-image: url("./images/main-bg.png");
  background-size: cover;
  height: 130%;
  width: 100vw;
  overflow-x: hidden;
  z-index: -100;
}

.background-footer {
  /* background-image: url("./images/bg-new.svg"); */
  background-size: cover;
}

.background-element-holder {
  position: fixed;
  overflow-x: hidden;

}

.background-element {
  width: 80vw;
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat;
  position: fixed;
  top: 0px;
  left: -300px;
  z-index: -90;
}

.back-piece {
  width: 200px;
  height: 200px;
  background-size: contain;
  position: fixed;
}
.pice-1 {
  background-image: url("./images/torus-2.webp");
}

.back-piece-1 {
  background-image: url("./images/gift-purple.webp");
  scale: 1.3;
  left: 80vw;
  top: 10vh;
  animation: bobAround 10s ease 0s infinite;
  animation-direction: alternate;
}

.back-piece-2 {
  background-image: url("./images/controller-2.webp");
  top:20vh
  /* left: 80vw;
  top: 60vh;
  animation: bobAround 14s ease 0s infinite;
  animation-direction: alternate;
  animation-delay: -4s; */
}

.back-piece-3 {
  background-image: url("./images/piece-1.webp");
  scale: 0.7;
  left: 5vw;
  top: 100vh;
  /* animation: bobAround 20s ease 0s infinite;
  animation-delay: -12s; */
}
.body-data {
  max-width: 80%;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 2em;
  font-weight: 50;
  color: #444444;
  padding-top: 8vh;
  padding-bottom: 5vh;
}

.back-piece-4 {
  background-image: url("./images/confetti-3.webp");
  scale: 0.5;
  left: 60vw;
  top: 80vh;
  animation: bobAround 20s ease 0s infinite;
  animation-delay: -12s;
}

.back-piece-5 {
  background-image: url("./images/torus-2.webp");
  scale: 1.2;
  left: -10vw;
  top: 35vh;
  animation: bobAround 25s ease 0s infinite;
  animation-delay: -8s;
}

.back-piece-6 {
  background-image: url("./images/confetti-5.png");
  scale: 0.9;
  left: 73vw;
  top: 120vh;
  animation: bobAround 16s ease 0s infinite;
  animation-delay: -8s;
}

.el1 {
  /* background-image: url("./images/bg-el1.webp"); */
}

.main-content {
  font-family: "Tilt Neon";
}

.landing-page {
  font-size: 4em;
  text-align: center;
  width: 100vw;
  height: 100vh;
}

.landing-center {
  margin: auto;
  padding-top: 50vh;
  max-width: 1200px;
  height: 60vh;
}

.landing-phone {
  display: inline-block;
  vertical-align: middle;
  min-width: 35%;
  height: fit-content;
}

.hero-pointer {
  color: #8d0ef5;
  font-size: 4em;
  text-align: center;
  max-width: 50%;
  font-family: "Montserrat-Regular";
}

video {
  object-fit: cover;
}

.phone-bg {
  margin: auto;
  background-image: url("./images/Phone.webp");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  width: 39vh;
  height: 80vh;
  animation: subtleBob 15s ease 0s infinite alternate;
}

.phone-video {
  width: 100%;
  height: 100%;
  mask-size: contain;
  mask: url("./images/video-mask.svg");
  -webkit-mask-size: contain;
  -webkit-mask-box-image: url("./images/video-mask.svg");
}

.landing-page-text-set {
  padding: 20px;
  display: inline-block;
  min-width: 60%;
}

.landing-page-text-holder {
  height: 0px;
}

.landing-page-text {
  opacity: 0;
  animation: shiftUp 9s ease-in-out 0s infinite;
}

.section-background {
  background-color: #fff;
  width: 100vw;
  height: 45vh;
}

.section-background-curve-holder {
  display: block;
  height: 20vh;
  width: 120vw;
  transform: translateY(1%);
  overflow: hidden;
}

.section-background-curve {
  /* display: block; */
  background-image: url("./images/curve-bg.svg");
  /* background-size: 100vw 20vh;
  background-repeat: no-repeat;
  width: 100%;
  /* height: 100%;  */
}

.mid-section {
  padding: 12vw;
  padding-top: 18vh;
}

.default-center {
  margin: auto;
  max-width: 100vw;
  min-height: 0vh;
}

.section-image {
  display: inline-block;
  vertical-align: middle;
  width: 35%;
}

.subsection-text {
  display: inline-block;
  /* vertical-align: middle; */
  width: 53%;
  box-sizing: border-box;
  padding: 60px;
}

.image-icon {
}

.image-icon img {
  width: 100%;
  margin-left: -4vw;
}

.main-text {
  font-size: 2.5em;
}

.dark-text {
  background: -webkit-repeating-linear-gradient(
    rgb(194, 66, 194),
    rgb(119, 43, 119) 1.25em
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.light-text {
  background: -webkit-repeating-linear-gradient(#fff, #fdf 1.25em);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.embedded-image {
  height: 0px;
  margin-left: 53vw;
  background-attachment: fixed;
}

.embedded-image img {
  position: absolute;
  bottom: 0px;
  width: 70%;
}

.embedded-scroller {
  position: absolute;
  bottom: 0;
  width: 90%;
}

.embedded-image-holder {
  transform: translateY(-100%);
  height: 100vh;
  overflow: hidden;
}

.feature-section {
  margin-top: 13vh;
  padding-top: 10vh;
  padding-bottom: 0vh;
  /* background-image: url("./images/bg-new.webp"); */
  background-repeat: no-repeat;
  background-size: cover;
}
.feature-pice {
  background-image: url("./images/piece-1.png");
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 100;
}

.feature-set {
  text-align: center;
  padding-top: 10px;
}

.feature-element {
  display: inline-block;
  vertical-align: top;
  align-items: center;
  width: 25%;
  padding: 20px;
}

.feature-image {
}

.feature-image img {
  max-width: 100%;
  margin-left: 0%;
}

.feature-title {
  font-size: 2.5em;
  padding: 20px;
  font-weight: 700;
  font-family: "Montserrat-Bold";
}

.store {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 50px;
  padding-bottom: 50px;
  /* width: 70%; */
}
.store-button {
  width: 50%;
  background-color: #8e00ff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  border-radius: 20px;
  cursor: pointer;
}
.only-ios {
  font-family: "Montserrat-Bold";
  color: white;
  padding: 0px 10px;
}
.feature-desc {
  font-family: "Montserrat-Regular";
  font-size: 1.5em;
  font-weight: 400;
}

.center-text {
  text-align: center;
}

.mid-section-text {
  padding-top: 20vh;
  padding-left: 7vw;
}

.join-section-text {
  padding-top: 5vh;
}

.join-section-background {
  background-color: #fff;
  width: 100vw;
  height: 40vh;
}

.join-type-set {
  text-align: center;
  vertical-align: top;
}

.join-type {
  padding: 10px;
  display: inline-block;
  user-select: none;
  cursor: pointer;
  background-image: url("./images/bg.webp");
  background-position: -100% 50%;
  background-repeat: no-repeat;
  border-radius: 100px;
  background-size: 200%;
  transition: 0.3s;
}

.join-type:hover {
  scale: 1.05;
}

.join-type:active {
  scale: 0.9;
}

.join-type-set input {
  visibility: hidden;
}

.join-type-set input:checked ~ .join-type {
  color: #fff;
  background-position: 50% 50%;
}

.join-type img {
  width: 70px;
}

.comm-enter-holder {
  width: 100%; /* Set to 100% of the parent container */
  margin: 0 auto; /* Center the block horizontally */
  text-align: center; /* Center text alignment */
}

.button {
  border-radius: 500px;
  color: #eee;
  font-size: 2em;
  padding: 16px 80px;
  user-select: none;
  cursor: pointer;
  background-size: 500%;
  background-position: 40% 60%;
  background-image: url("./images/bg.webp");
  transition-duration: 0.3s;
}

.button:hover {
  background-size: 500%;
  background-position: 50% 80%;
  color: #fff;
}

.button:active {
  scale: 0.9;
  background-size: 500%;
  background-position: 80% 80%;
}

.footer {
  min-height: 30vh;
  padding: 50px;
  /* background-image: url("./images/bg-footer.webp"); */
}

.footer-detail {
  text-align: center;
  font-family: "Montserrat-Regular";
}

.footer-detail > div {
  padding: 10px;
}

.mini-footer-detail {
  padding: 30px;
  font-size: 1.5em;
  font-family: "Montserrat-Regular";
}

.social-set {
  text-align: center;
}

.social-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0px 5px;
  background-size: cover;
}

.facebook-icon {
  background-image: url("./images/facebook-icon.svg");
}

.twitter-icon {
  background-image: url("./images/twitter-icon.svg");
}

.youtube-icon {
  background-image: url("./images/youtube-icon.svg");
}

.instagram-icon {
  background-image: url("./images/instagram-icon.svg");
}
.tiktok-icon {
  background-image: url("./images/tiktok.svg");
}
.linkedin-icon {
  background-image: url("./images/linkedin.svg");
}

.header {
  top: 0;
  position: fixed;
  z-index: 1000;
  height: 13vh;
  width: 100vw;
  box-sizing: content-box;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: top 0.3s, opacity 0.5s;
}



.header-logo {
  width: 12vw;
}
.header-name {
  width: 8vw;
}
.header-logo-container {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: left;
  cursor: pointer;
  width: 50%;
}
.header-logo-container-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30vw;
  margin-right: 50px;
}
.header-about {
  font-family: "Montserrat-Bold";
  color: #8035bc;
  padding: 5px;
  font-size: 1.7vw;
  cursor: pointer;
  text-decoration: none;
  font-weight: 700;
  width: 30vw;
}
.header-download {
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px;
  font-size: 1.8vw;
  background-color: #8e00ff;
  color: white;
  border-radius: 50px;
  box-shadow: #333;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  cursor: pointer;
  font-family: "Montserrat-Bold";
}

.explor {
  font-size: 2.4vw;
  color: white;
  font-weight: 700;
  font-family: "Montserrat-Bold";
}
.hero-container {
  flex-direction: row;
  height: 70vh;
  justify-content: space-between;
  align-items: center;
  padding-left: 5vw;
}

.hero-explore-img-1 {
  width: 40vw;
  position: absolute;
}
.hero-explore-img-2 {
  margin-left: 20vw;
  width: 25vw;
  margin-top: 50px;
  position: absolute;
}
.hero-explore-text {
  position: absolute;
  top: 37vh;
  width: 42%;
  left: 10vw;
}
.hero-explore-img {
  position: absolute;
  top: 33vh;
  width: 50%;
  right: -2vw;
}
.hero-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.see-how-works {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 50px;
  min-width: 60%;
}
.seemore-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 5vw;
}

.see-more-beme {
  font-size: 20px;
  color: white;
  text-decoration: none;
  font-family: "Montserrat-Bold";
  padding-top: -50px;
}

.modal-background {
  position: fixed;
  z-index: 100;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #3238;
}

.modal {
  max-width: 800px;
  min-height: 500px;
  background-color: #fff;
  border-radius: 20px;
  margin: auto;
  margin-top: 10vh;
  box-sizing: border-box;
  font-size: 2em;
  text-align: center;
  box-shadow: 5px 5px 5px #0002;
  overflow: hidden;
}

.modal-title-background {
  background-image: url("./images/bg.webp");
  background-size: 100%;
  height: 200px;
}

.linar-bg {
  background-image: linear-gradient(
    to top,
    #8e00ff,
    #8f00a2,
    #6e0059,
    #3d0426,
    #8e00ff
  );
}

.modal-content {
  padding: 50px 100px 50px 100px;
}

.congrat-title {
  font-size: 1.2em;
  background: -webkit-repeating-linear-gradient(#fff, #fdf 1.25em);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 70px;
}

.congrat-desc {
  font-size: 0.8em;
  text-align: left;
  padding: 10px;
  text-align: center;
}

.interest-option-set {
  padding: 10px 100px;
  text-align: left;
  font-size: 0.7em;
}

.interest-option {
}

.interest-option-text {
  display: inline-block;
}

.coupon-landing-center {
  font-family: Poppins;
  font-weight: 900;
  margin: auto;
  padding-top: 20vh;
  max-width: 1200px;
  height: 60vh;
}

.coupon-logo {
  width: 80%;
  margin: auto;
}

.coupon-logo img {
  max-height: 30vh;
  max-width: 100%;
}

.coupon-subtitle {
  font-size: 50%;
  padding-top: 10vh;
}

.platform-option-set {
  text-align: left;
  font-size: 0.7em;
}

.platform-option {
  display: inline-block;
  padding: 10px 30px;
}

.platform-option-text {
  display: inline-block;
  color: rgb(139, 94, 139);
  background-image: none;
  -webkit-background-clip: content-box;
  -webkit-text-fill-color: rgb(170, 77, 137);
}

.modal-complete {
  padding-top: 90px;
  font-size: 0.6em;
}

.modal-complete .button {
}

.landing-button {
  padding: 20px 100px;
  display: inline-block;
  font-size: 0.7em;
  background-color: #fff;
  background-image: none;
  transform: translateY(200px);
}

.successMessage {
  position: fixed;
  justify-content: center;
  align-self: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  color: white;
  padding: 15px;
  /* margin: 10px 0; */
  border-radius: 5px;
  text-align: center;
  color: #4caf50;
}

.responsive-text {
  font-size: 1rem;
  text-align: center;
  background-color: rgba(7, 7, 7, 0.242);
  padding: 15px;
}

/* General form styles */
.input-div {
  max-width: 480px; /* Adjust the width as needed */
  margin: 20px auto;
  background: #ffffff;
  padding: 2em;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* subtle shadow for depth */
  font-family: "Poppins", sans-serif; /* Switching to Poppins for better readability */
}

/* Style for input fields */
.input-text {
  width: 100%; /* Full width */
  padding: 16px 20px; /* Comfortable padding */
  margin-bottom: 10px; /* Space between inputs */
  border: 2px solid #e0e0e0; /* Subtle border */
  border-radius: 8px; /* Rounded borders */
  box-sizing: border-box; /* Include padding in width */
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  color: #333;
  transition: border-color 0.3s; /* Smooth transition for hover effect */
}
.card-number {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
  background-color: #8d0ff5;
  padding: 0px;
  max-width: 100px;
  max-height: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 200px;
  color: white;
  align-items: center;
  font-family: "Montserrat-Bold";
}
.card-description {
  font-size: 2rem;
  text-align: left;
  color: #444444;
  width: 60%;
  padding-left: 50px;
  font-family: "Montserrat-Bold";
  font-weight: 50;
}

.card-button {
  background-color: #8d0ff5;
  color: white;
  border: none;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  border-radius: 50px;
  cursor: pointer;
  font-family: "Montserrat-Bold";
}
.above-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 100px; */
}
.hero-pointer-1 {
  color: #8d0ef5;
  font-size: 4em;
  text-align: center;
  /* max-width: 70%;  */
  font-family: "Montserrat-Bold";
}
.body-data-1 {
  font-family: "Montserrat-Regular";
  /* max-width: 60%;   */
  text-align: center;
  color: #444444;
  font-size: 1.7em;
  font-weight: lighter;
  color: #444444;
  text-align: center;
  margin-top: -20px;
}

.input-text:focus {
  border-color: #5620ad; /* Highlight color when input is focused */
}

/* Placeholder color */
::placeholder {
  color: #aaa; /* Placeholder text color */
}

/* Style for the button */
.join-button {
  padding: 16px 20px; /* Comfortable padding */
  background-color: #7b1fa2; /* Use your brand color */
  color: white;
  text-align: center;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  transition: background-color 0.3s, transform 0.1s; /* Smooth transition for hover effect */
  margin-top: 10px; /* Space above the button */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* subtle shadow for depth */
}

.join-button:hover {
  background-color: #9c27b0; /* A lighter shade for hover state */
  transform: translateY(-2px); /* Slight raise effect on hover */
}

.join-button:active {
  transform: translateY(1px); /* Slight press down effect on click */
}

.phone-footer {
  font-family: "Montserrat-Bold";
  font-weight: 700;
  font-size: 1.5rem;
}
.contact-us-footer {
  font-family: "Montserrat-Bold";
  font-weight: 700;
  font-size: 1.5rem;
}
.font-fam {
  font-family: "Montserrat-Regular";
}

.hero-how {
  font-family: "Montserrat-Bold";
  font-size: 4.3rem;
  text-align: center;
  color: #8d0ef5;
  /* margin-top: 15vh; */
}
.body-data {
  font-family: "Montserrat-Regular";
  max-width: 70%;
  text-align: center;
  font-size: 1.7em;
  font-weight: lighter;
  color: #444444;
  text-align: center;
}
.input-data {
  padding: 25px 60px;
  border: none;
  background-color: #ececec;
  border-radius: 10px;
  width: 30vw;
  font-family: "Montserrat-Regular";
}

.text-area-view {
  padding: 25px 60px;
  border: none;
  background-color: #ececec;
  border-radius: 10px;
  font-family: "Montserrat-Regular";
  width: 30vw;
  height: 20vh;
}
.contact-us-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 10px;
}

/* Hiding the spin button on number inputs in WebKit browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.parsley-errors-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.parsley-errors-list li {
  color: red;
  font-size: 0.9em;
  margin-top: 1px;
  text-align: left;
}

.iti {
  width: 100%;
}
.centerd-texts {
  font-family: "Montserrat-Bold";
  color: #8c0ef7;
  width: 45vw;
}
.emb-images-cont {
  margin-top: -10vh;
}

.downloader {
  width: 20vw;
}
@media only screen and (max-width: 900px) {
  .hero-how {
    font-size: 1.8rem;
  }
  .body-data {
    font-size: 1.2rem;
    min-width: 90%;
  }
  .card-number {
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
    font-size: 16px;
  }
  .card-description {
    font-size: 1rem;
    text-align: left;
    color: #444444;
    width: 70%;
    padding-left: 10px;
    font-family: "Montserrat-Bold";
    font-weight: 50;
  }
  .card-button {
    background-color: #8d0ff5;
    color: white;
    border: none;
    padding: 2px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 20px;
    cursor: pointer;
    font-family: "Montserrat-Bold";
  }
  .hero-pointer-1 {
    color: #8d0ef5;
    font-size: 2em;
    text-align: center;
    /* max-width: 70%;  */
    font-family: "Montserrat-Bold";
  }
  .body-data-1 {
    font-size: 1.2rem;
  }
  .header {
    height: 10vh;
    display: flex;
    justify-content: center;
  }
  .header-logo {
    width: 20vw;
  }
  .header-name {
    width: 15vw;
  }

  .header-about {
    font-size: 3.5vw;
    margin-right: -200px;
  }
  .mid-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section-image {
    width: 90vw;
  }
  .subsection-text {
    width: 100vw;
    text-align: center;
    
  }
  .earn {
    font-family: 'Montserrat-Bold';
    margin-top: -10vh;
  }
  .centerd-texts {
    width: 80vw;
    margin-top: -10vh;
    text-align: center;
    margin-left: -10vw;
    z-index: 1;
    position: absolute;
  }
  .emb-images-cont {
    overflow-x: hidden;
    margin-top: -20vh;
  }
  .header-download {
    font-size: 6vw;
    padding-left: 15vw;
    padding-right: 15vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
  }
  .feature-set {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .downloader {
    width: 40vw;
  }
  .store {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: -5vh;
  }
  .store-button {
    width: 80%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    border-radius: 20px;
    cursor: pointer;
  }
  .only-ios {
    font-family: "Montserrat-Bold";
    color: white;
    padding: 0px 10px;
    color: #aaaaaa;
    text-align: center;
    margin-top: 10px;
  }
  .feature-element {
    width: 90%;
  }
  .header-logo-container-1 {
    /* width: 65vw; */
    /* display: ; */
  }
  .embedded-image {
    margin-left: 30%;
    margin-top: 5vh;
  }
  .embedded-image-holder {
    width: 75%;
    margin-top: -5vh;
  }

  .explor {
    font-size: 30px;
    margin-top: 0vh;
  }
  .hero-explore {
    top: 20vw;
    width: 90%;
  }
  .hero-container {
    height: 90vh;
    overflow-x: hidden;
  }
  .hero-explore-img {
    top: 13vh;
    width: 100%;
    left: 0;
  }
  .hero-explore-img-1 {
    width: 100vw;
  }
  .hero-explore-img-2 {
    margin-left: 60vw;
    margin-top: 10vh;
    width: 50vw;
  }
  .hero-explore-text {
    top: 60vh;
    width: 80vw;
    text-align: center;
    overflow: hidden;
  }
  .hero-buttons-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .seemore-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .see-how-works {
    margin-left: 0px;
    margin-top: 50px;
  }
  .main-text {
    font-size: 1.5em;
    font-family: "Montserrat-Bold";
    margin-bottom: -50px;
  }
  .image-icon img {
    width: 100vw;
    margin-left: -4vw;
  }
  .default-center {
    margin: auto;
    overflow-x: hidden;
    /* max-width: 100vw; */
    min-height: 01vh;
    padding-top: 5vh;
  }
  .social-set {
    padding-top: 10vh;
    text-align: center;
  }
  
}


.bg-wave-pattern {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="150px" viewBox="0 20 1440 300"><path fill="%23ffffff" d="M 0 160 L 83 146 C 197 127 342 102 484 79 C 697 45 773 63 961 78 C 1131 100 1280 128 1360 144 L 1440 160 V 320 H 0 Z"></path></svg>');
  background-repeat: no-repeat;
  background-size: cover;
}

.custom-dot-list .react-multi-carousel-dot button {
  background-color: white;
  border: 0px
}

.custom-dot-list .react-multi-carousel-dot--active button {
  background-color: #F2419C;
}